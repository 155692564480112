<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-roles' }">{{
            $t("MENU.ITEM.ACCOUNTS.ROLES")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingRole"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>
          <RoleForm v-if="!firstLoader" :form="form" />
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="chip"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            class="mx-auto"
            type="image"
          ></v-skeleton-loader>
          <v-overlay :value="!firstLoader && isLoadingRole">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ Array.isArray(error) ? error[0] : error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingRole"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_ROLE } from "@/modules/accounts/role/store/role.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

import RoleForm from "../components/RoleForm.vue";

export default {
  mixins: [validationMixin, formBuilderMixin],
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown,
    RoleForm
  },
  validations() {
    return this.generateFormValidationsOutOfSchemaJson(this.schemaJson);
  },
  data() {
    return {
      errors: {},
      firstLoader: false,
      role: {},
      permissions: []
    };
  },
  computed: {
    ...mapGetters(["isLoadingRole"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "role-name-input",
            groupId: "role-name-group",
            required: "required",
            feedback_id: "role-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.NAME",
              placeholder: "FORM_PLACEHOLDERS.NAME",
              validation: "FORM.VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "name"
          }
        ]
      };
    }
  },
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Роли", route: { name: "list-roles" } },
      { title: "Добавяне на роля" }
    ]);
    this.generateFormOutOfSchemaJson(this.schemaJson);
    this.$set(this.form, "permissions", []);
  },
  methods: {
    onSave(next) {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Моля попълнете всички задължителни полета"
        });
        return;
      }
      this.$store
        .dispatch(SAVE_ROLE, this.form)
        .then(data => {
          this.errors = data.data.errors;
          let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-role",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-role" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-roles" });
          }
        })
        .catch(response => {
          this.errors = response.data.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response?.data?.message
          });
        });
    }
  }
};
</script>
