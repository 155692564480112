<template>
  <div v-if="!loading">
    <label>{{ $t("FORM_LABELS.PERMISSIONS") }}</label>
    <v-checkbox
      id="selectAllPermissions"
      v-model="selectAllPermissions"
      :indeterminate="selectAllIndeterminate"
    >
      <template v-slot:label>
        <strong>{{ $t("FORM_LABELS.SELECT_ALL") }}</strong>
      </template>
    </v-checkbox>
    <v-divider></v-divider>
    <b-form-row>
      <b-col
        :sm="6"
        :md="6"
        :lg="4"
        v-for="(group, index) in groupedPermissions"
        v-bind:key="'permission-key-' + index"
      >
        <v-checkbox
          style="font-size:10px;"
          :id="'permission-group-' + index"
          :label="$t(`PERMISSIONS.${index}`)"
          :indeterminate="
            form.permissions.filter(x => x.name.includes(index)).length > 0 &&
              group.length >
                form.permissions.filter(x => x.name.includes(index)).length
          "
          :input-value="
            group.length ===
              form.permissions.filter(x => x.name.includes(index)).length
          "
          @change="handleGroupInput($event, index)"
        ></v-checkbox>
        <div class="ml-4" v-for="(permission, i) in group" :key="i">
          <v-checkbox
            style="font-size:10px;"
            :id="'permission-' + i"
            v-model="form.permissions"
            :label="$t(`PERMISSIONS.${permission.name}`)"
            :value="permission"
          ></v-checkbox>
        </div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import _ from "lodash";
import { FETCH_PERMISSIONS } from "../store/role.module";

export default {
  name: "RoleForm.vue",
  props: {
    form: Object
  },
  data() {
    return {
      permissions: [],
      loading: true
    };
  },
  computed: {
    groupedPermissions() {
      return _.groupBy(this.permissions, x => {
        let perm = x.name.split("-");
        perm.pop();
        return perm.join("-");
      });
    },
    selectAllIndeterminate() {
      return this.permissions
        ? this.form.permissions.length < this.permissions.length &&
            this.form.permissions.length > 0
        : false;
    },
    selectAllPermissions: {
      get: function() {
        return this.permissions
          ? this.form.permissions.length == this.permissions.length
          : false;
      },
      set: function(value) {
        var selected = [];

        if (value) {
          this.permissions.forEach(function(permission) {
            selected.push(permission);
          });
        }

        this.form.permissions = selected;
      }
    }
  },
  mounted() {
    this.getPermissions();
  },
  methods: {
    getPermissions() {
      this.$store
        .dispatch(
          FETCH_PERMISSIONS,
          this.$url.transformParams({
            onlySelectValues: true
          })
        )
        .then(({ data }) => {
          this.permissions = data.data;
          this.loading = false;
        })
        .catch(() => {
          this.$notify({
            group: "notify",
            type: "error",
            text: this.$t("NOTIFY.DEFAULT_ERROR")
          });
        });
    },
    handleGroupInput(value, group) {
      let selected = [];
      this.form.permissions.forEach(x => {
        if (!x.name.includes(group) || value) {
          selected.push(x);
        }
      });
      if (value) {
        selected = [
          ...selected,
          ...this.permissions.filter(x => x.name.includes(group))
        ];
      }

      this.form.permissions = selected;
    }
  }
};
</script>

<style></style>
